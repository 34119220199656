import React, { useRef } from 'react'
import cn from 'classnames'

// Components
import { Box as ArticleBox } from '@components/Article'
import { Container, Row, Col } from 'reactstrap'
import { Link, graphql } from 'gatsby'
import Section from '@components/Section'
import SEO from '@components/SEO'

// Utils
import { kebabCase, upperFirst } from 'lodash'
import { getThemesByWords } from '@utils/themes'

const Tema = ({
  pageContext: { theme, themeName, themes },
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const posts = edges.filter((post) => {
    // Escludi gli articoli non pubblicati
    if (!post.node.frontmatter.published) {
      return false
    }
    // Escludi gli articoli che non hanno parole
    if (
      !Array.isArray(post.node.frontmatter.words) ||
      post.node.frontmatter.words.length === 0
    ) {
      return false
    }
    const themesOfPost = getThemesByWords(
      themes,
      post.node.frontmatter.words.map((w) => w.value),
    )
    return !!themesOfPost.find((t) => t.name === themeName)
  })

  return (
    <main>
      <SEO title={upperFirst(theme.name)} description={theme.description} />
      <Container>
        <Row className="mb-lg-5">
          <Col md="7" className="my-3 pb-5">
            <header className="mt-5">
              <p className="u-text--detail">Tema</p>
              <h1 className="mb-3">{upperFirst(theme.name)}</h1>
              <p>{theme.description}</p>
            </header>
            <p className="u-text--detail mt-5">Articoli</p>
            {posts.map((p) => {
              const { title, date } = p.node.frontmatter
              const { slug, description } = p.node.fields
              return (
                <div className="mt-4">
                  <ArticleBox
                    key={title}
                    title={title}
                    date={date}
                    excerpt={description}
                    slug={slug}
                  />
                </div>
              )
            })}
          </Col>
          <Col md={{ size: 3, offset: 1 }} className="my-3 pb-5">
            <p className="u-text--detail mt-5">Le parole di questo tema</p>
            <ul className="list-unstyled">
              {theme.words.map((word) => (
                <li key={word}>
                  <Link to={`parola/${kebabCase(word)}`}>
                    {upperFirst(word)}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Tema

export const tagPageQuery = graphql`
  query ThemesQuery {
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          published: { eq: true }
        }
      }
    ) {
      edges {
        node {
          ...BoxFrontmatter
        }
      }
    }
  }
`
